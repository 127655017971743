import { useState, useContext, useMemo, useCallback, useEffect } from "react"
import sortBy from "lodash-es/sortBy"
import orderBy from "lodash-es/orderBy"
import { useFirestoreConversation } from "services/firestore/conversations"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import {
  IContactOrContactGroup,
  ConversationRoutes,
  IResolvedConversation,
  IContact,
} from "./conversations.types"
import { ConversationContext } from "./context"
import { ConversationActions, ConversationTypes } from "./actions"
import { useFirestoreContact } from "services/firestore/contact"
import { UserContext } from "app/User/context"
import sum from "lodash-es/sum"
import { getUsers } from "services/user"
import { IUser } from "app/User/types"
import { UserActions, UserTypes } from "app/User/actions"
import { updateCollectionItemByID } from "utils/array"
import { CompanyContext } from "app/Company/context"

export const useConversation = () => {
  const {
    dispatch,
    state: { contacts, groups, conversations, currentConversationID },
  } = useContext(ConversationContext)
  const {
    state: { user },
    dispatch: userDispatch,
  } = useContext(UserContext)
  const {
    state: { company },
  } = useContext(CompanyContext)
  const { deleteCompanyContact } = useFirestoreContact()

  const contactsAndGroupsSorted: IContactOrContactGroup[] = useMemo(() => {
    const mappedGroups = groups.map((group) => {
      return {
        ...group,
        is_group: true,
        contact_ids: group.contact_ids.reduce((results: IContact[], x) => {
          const groupContact = contacts.find((contact) => contact.id === x.id)
          if (groupContact) results.push(groupContact)
          return results
        }, []),
      }
    })

    const newCollection = sortBy(
      [
        ...orderBy(mappedGroups, ["title"]),
        ...orderBy(contacts, ["first_name"]).filter(
          (contact) => !!contact.first_name
        ),
      ],
      ["is_group"]
    )

    return newCollection
  }, [contacts, groups])

  const contactsSorted: IContact[] = useMemo(
    () => orderBy(contacts, ["first_name", "title"]),
    [contacts]
  )

  const conversationsSorted: IResolvedConversation[] = useMemo(() => {
    if (currentConversationID) {
      // Only update the current conversation
      const newConversation = conversations.find(
        (convo) => convo.id === currentConversationID
      )
      const newContact = contacts.find(
        (contact) => contact.id === currentConversationID
      )

      if (newContact && newConversation) {
        const updatedConversations = updateCollectionItemByID<
          IResolvedConversation
        >(conversations, currentConversationID, {
          ...newConversation,
          contact: newContact,
        })
        return orderBy(updatedConversations, [
          "last_message_timestamp",
          "contact.first_name",
          "contact.msisdn",
        ]).reverse()
      }
    } else if (company?.id && user?.id) {
      /* const newConversations = getUserUnreadMessages({
        contactIDs: conversations.map((convo) => convo.id),
        userID: user?.id.toString(),
        companyID: company?.id.toString(),
      }).then(({ data: unreadCountMap }) => { */
      // Otherwise update all conversations with the updated contact.
      const newConversations = conversations.map((convo) => {
        const updatedContact = contacts.find(
          (contact) => contact.id === convo.id
        )

        if (updatedContact) {
          return {
            ...convo,
            // unread_count: unreadCountMap[convo.id],
            contact: updatedContact,
          }
        } else {
          return convo
        }
      })
      // })
      return orderBy(newConversations, [
        "last_message_timestamp",
        "contact.first_name",
        "contact.msisdn",
      ]).reverse()
    }

    return orderBy(conversations, [
      "last_message_timestamp",
      "contact.first_name",
      "contact.msisdn",
    ]).reverse()
  }, [conversations, contacts])

  const unreadMessages: number = useMemo(() => {
    return sum(
      conversationsSorted.map((conversation) => conversation.unread_count)
    )
  }, [conversationsSorted, contactsAndGroupsSorted])

  const {
    subscribeToContacts,
    subscribeToUserConversations,
    subscribeToContactGroups,
    unsubscribeToContacts,
    unsubscribeToUserConversations,
    unsubscribeToGroups,
  } = useFirestoreConversation()

  const getData = async () => {
    dispatch(
      ConversationActions(ConversationTypes.SetLoading, { loading: true })
    )
    const resContacts = await subscribeToContacts()
    await subscribeToContactGroups(resContacts)
    const usersRequest = await getUsers()
    if (usersRequest?.data.Users.length > 0) {
      const userList: Partial<IUser>[] = usersRequest?.data.Users.map(
        (u: IUser): Partial<IUser> => {
          const { firstName, id, lastName } = u
          const shortName = [
            firstName,
            lastName
              .split(" ")
              .map((str, i) => `${str.substring(0, 1)}.`)
              .join(" "),
          ].join(" ")
          return {
            ...u,
            firstName,
            lastName,
            id,
            shortName,
          }
        }
      )
      userDispatch(UserActions(UserTypes.SetUserList, { users: userList }))
    }
  }

  const deleteContact = async (id: string) => {
    try {
      await deleteCompanyContact(id)
    } catch (error) {
      throw new Error(error)
    }
  }

  const onNavigate = (route: ConversationRoutes) => {
    dispatch(
      ConversationActions(ConversationTypes.SetRoute, {
        key: route,
      })
    )
  }

  const onSetNavigationState = (routes: ConversationRoutes[]) => {
    dispatch(
      ConversationActions(ConversationTypes.SetRoutes, {
        routes,
      })
    )
  }

  const onToggleScreen = useCallback((route: ConversationRoutes) => {
    dispatch(
      ConversationActions(ConversationTypes.ToggleRoute, {
        key: route,
      })
    )
  }, [])

  return {
    contactsAndGroupsSorted,
    contactsSorted,
    conversationsSorted,
    unreadMessages,
    getData,
    subscribeToUserConversations,
    subscribeToContacts,
    unsubscribeToContacts,
    unsubscribeToUserConversations,
    unsubscribeToGroups,
    deleteContact,
    onNavigate,
    onToggleScreen,
    onSetNavigationState,
  }
}
