import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import "twin.macro"
import { useParams } from "@reach/router"
import cogoToast from "@clevertrackdk/cogo-toast"
import Icon from "lib/Icon"
import Container from "app/Container"
import { getReports } from "services/reports"
import { Card, Button, Responsive } from "@clevertrack/shared"
import Report from "./Report"

const StyledReports = styled(Container)`
  height: 100%;
  width: 100%;
  padding: 1rem 1rem 4rem;
  /* position: absolute;
  top: 0;
  bottom: 0;
  z-index: 50; */

  iframe {
    border: none;
  }

  > header {
    display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // padding: 0 1rem;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 0.8em;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
    height: 100%;
    header {
      padding: 2rem 2rem;
      // flex-direction: column;
      align-items: flex-start;
    }
  `}
`

const StyledReportsWrapper = styled.div`
  display: grid;
  ${(props) => props.theme.media.tablet_landscape_up`
    grid-template-columns: repeat(auto-fit, 30rem);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    padding: 2rem;
  `}
`

const StyledReportWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  max-width: 100vw;
  padding-bottom: 4rem;
  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

const StyledButton = styled(Button)`
  background: white;
  color: ${(props) => props.theme.colors.secondary};
  box-shadow: ${(props) => props.theme.mapButtonShadow};
`

const StyledMiniReport = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  .img-wrapper {
    overflow: hidden;
    height: 15rem;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  h4 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    font-size: 0.8em;
  }

  a {
    color: ${(props) => props.theme.colors.primary};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    flex: 0 1 30rem;

    & + & {
      margin-top: 0;
    }
  `}
`

function Reports({ ...props }) {
  const [selectedReport, setSelectedReport] = useState(null)
  const [availableReports, setAvailableReports] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentReport, setCurrentReport] = useState(null)
  const params = useParams()

  const getAvailableReports = async () => {
    setLoading(true)
    try {
      const reportsResponse = await getReports()
      if (reportsResponse && reportsResponse.data.result === "OK") {
        if (params.reportId) {
          findAndSetSelectedReport(
            reportsResponse.data.reports,
            params.reportId
          )
        }
        setAvailableReports(reportsResponse.data.reports)
        setLoading(false)
      } else {
        cogoToast.error(`Could not load reports at this time`)
        setLoading(false)
      }
    } catch (error) {
      cogoToast.error("Could not load reports at this time")
      setLoading(false)
    }
  }

  const findAndSetSelectedReport = (reportsArr, matchId) => {
    const report = reportsArr.find((r) => r.id === matchId)
    if (report) {
      const { EmbedToken, EmbedURL, ReportID, ReportTitle, ReportText } = report
      handleSelectReport(
        EmbedToken,
        EmbedURL,
        ReportID,
        ReportTitle,
        ReportText
      )
    }
  }

  const onBackButtonHandler = () => {
    setSelectedReport(null)
    navigate(`/app/reports`)
  }

  const handleSelectReport = (
    token,
    embed_url,
    report_id,
    title,
    text,
    id = null
  ) => {
    setSelectedReport({
      token,
      embed_url,
      report_id,
      title,
      text,
    })
    if (id) navigate(`/app/reports/${id}`)
  }

  useEffect(() => {
    if (!currentReport) getAvailableReports()
  }, [currentReport])

  useEffect(() => {
    if (params.reportId === "") {
      setSelectedReport(null)
    } else {
      findAndSetSelectedReport(availableReports, params.reportId)
    }
  }, [params, availableReports])

  const setReportRef = (report) => {
    setCurrentReport(report)
  }

  return selectedReport === null ? (
    <StyledReports>
      <Responsive
        tabletLandscape={
          <>
            <header>
              <h2 tw="my-0">Rapporter</h2>
            </header>
          </>
        }
      />
      <StyledReportsWrapper>
        {!loading &&
          availableReports.map((report) => {
            const {
              EmbedToken,
              EmbedURL,
              ReportID,
              ReportTitle,
              ReportText,
              ReportImage,
              id,
            } = report
            return (
              <StyledMiniReport
                key={ReportID}
                size="sm"
                onClick={() =>
                  handleSelectReport(
                    EmbedToken,
                    EmbedURL,
                    ReportID,
                    ReportTitle,
                    ReportText,
                    id
                  )
                }
              >
                <div className="img-wrapper">
                  <img src={ReportImage} alt={ReportText} />
                </div>
                <h4>{ReportTitle}</h4>
                <p>{ReportText}</p>
                <a>Se rapport</a>
              </StyledMiniReport>
            )
          })}
      </StyledReportsWrapper>
    </StyledReports>
  ) : (
    <StyledReports {...props}>
      <header tw="flex flex-col md:(flex-row items-center justify-between)">
        <h3 tw="mt-0">
          <span tw="flex items-center justify-between md:(justify-start)">
            {selectedReport.title}
            <StyledButton
              onClick={onBackButtonHandler}
              icon="left"
              size="sm"
              invert
              tw="md:(hidden)"
            >
              <Icon size="sm" icon="long-arrow-left" />
              <span>Tilbage</span>
            </StyledButton>
            {currentReport && (
              <StyledButton
                tw="hidden md:(block ml-4 py-0)"
                invert
                icon="right"
                size="sm"
                onClick={() => currentReport.print()}
              >
                <span>Udskriv</span>
                <Icon size="sm" icon="print" />
              </StyledButton>
            )}
          </span>
          <span tw="block font-normal normal-case text-2xl">
            {selectedReport.text}
          </span>
        </h3>
        <div tw="flex flex-col items-end hidden md:block">
          <StyledButton
            onClick={onBackButtonHandler}
            icon="left"
            size="sm"
            invert
          >
            <Icon size="sm" icon="long-arrow-left" />
            <span>Tilbage</span>
          </StyledButton>
        </div>
      </header>
      <StyledReportWrapper>
        <Report onReportLoaded={setReportRef} {...selectedReport} />
      </StyledReportWrapper>
    </StyledReports>
  )
}

export default Reports
